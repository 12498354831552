import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, TextField , Fab, Icon, Box, } from '@material-ui/core';

// global data
import ServerData from "./ServerData";
import SendServiceData from "./SendServiceData";
import SessionData from "./SessionData";
import SettingsData from "./SettingsData";
import Format from "../Utilities/Format";

export default function SendServices(props) {
    const [ignore, triggerChange] = React.useState(false);

    // clear the data that wll be used to send services
    SessionData.sendingNote = false 
    SendServiceData.URL = ""

    const onPersonallNoteChange = event => {
        let text = event.target.value
        SendServiceData.personallNoteText = text
        //SettingsData.CacheLocally = ('personallNoteText', text)
        window.localStorage.setItem('personallNoteText', text)
        SendServiceData.canSendServicesText = Format.validPhoneNumber(SendServiceData.phoneNumberText)
        triggerChange(!ignore)
    }

    const onPhoneNumberChange = event => {
        let text = event.target.value
        //console.log("onPhoneNumberChange -> "+ text)
        SendServiceData.phoneNumberText = text
        //SettingsData.CacheLocally = ('phoneNumberText', text)
        window.localStorage.setItem('phoneNumberText', text)
        SendServiceData.canSendServicesText = Format.validPhoneNumber(SendServiceData.phoneNumberText)
        triggerChange(!ignore)
    }

    const sendServiceToYouth = () => {
        // SendServiceData.servicesManifest is built in FindServices when opening SendServices
        //console.log("sendServiceToYouth phone = |"+ SendServiceData.phoneNumberText + "|"  + SendServiceData.emailAddress)
        if (Format.validEmailAddress(SendServiceData.emailAddress)){
            SendServiceData.servicesManifest.emailAddress = SendServiceData.emailAddress
            //console.log("SSD.sM with email:" + SendServiceData.emailAddress)
        }
        else if (Format.validPhoneNumber(SendServiceData.phoneNumberText)) {
            SendServiceData.servicesManifest.phoneNumber = Format.extractNumberString(SendServiceData.phoneNumberText)
            //console.log("SSD.sM with phone:" + SendServiceData.servicesManifest.phoneNumber)
        }
        else 
        {
            alert("INTERNAL ERROR: There should always be a phone number or email address for sending services.")
            return;
        }
        SendServiceData.servicesManifest.personalNote = SendServiceData.personallNoteText

        // build SendServiceData.URL here
        SendServiceData.URL = (ServerData.getServerRootUrl()   + 
                                "postNote?department=" + SettingsData.policeDepartment['agency'] + 
                                "&email=" + SettingsData.getActualEmailAddress() +
                                "&noteType=services" +
                                "&noteText=null")
        SendServiceData.URL = SendServiceData.URL.replace(/ /g,'%20')
        //console.log ("SendServiceData.URL: "+SendServiceData.URL)
        // The URL is posted in FindServices.componentDidMount() 
        // the following SessionData.sendingNote flag enables posting
        SessionData.sendingNote = true
        setScreen('find-services')
    }

    const updateServiceScreen = () => {
        triggerChange(!ignore)
    }

    SendServiceData.updateServiceScreen = updateServiceScreen
    var setScreen = props.setScreen
    const BothTextAndEmail=false
    //var ifPlural = (1<SessionData.selectedServices.length)?"S":""
    var servicesTitle = SessionData.selectedServices.length + " RECOMMENDED SERVICE" + ((1<SessionData.selectedServices.length)?"S":"") 

    return ( 
        <div>
            <SfyHeader context='SFY' screenId={'send-services'}  lastScreenId={props.lastScreenId}  setScreen={setScreen}  Settings={true} />
            <Box ml={3} mr={3}>
                <Grid container direction='column' >

                    <Box mb={-2}> 
                        <p style={{color:'#367BBD'}}> {servicesTitle} </p>
                    </Box>
                    <TextField
                        multiline={true}
                        id="generted-message"
                        color='primary'  
                        fullWidth = {true}
                        rows={9}
                        variant='outlined'   
                        defaultValue= {SendServiceData.generatedText}                                 
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    
                    <Box mb={-2} > 
                        <p style={{color:'#367BBD'}}>PERSONAL NOTE</p>
                    </Box>
                    <TextField
                        size='medium'
                        multiline={true}
                        id="personal-note-text"
                        placeholder='Optionally, enter your personal note to accompany the generated message.'
                        defaultValue={(0<SendServiceData.personallNoteText.length)?SendServiceData.personallNoteText:null}
                        color='primary'  
                        fullWidth = {true}
                        rows={2}
                        variant='outlined'  
                        onChange ={onPersonallNoteChange}                            
                    />

                    <Box mb={-2}> 
                        <p style={{color:'#367BBD'}}>SEND TO</p>
                    </Box>   
                    <Box mr={6}> 
                        <Grid container direction='row'>
                            <TextField
                                id="phone-number"
                                placeholder={(BothTextAndEmail?'Enter Phone Number and/or Email':'Enter Text Phone Number')}
                                defaultValue={(0<SendServiceData.phoneNumberText.length)?SendServiceData.phoneNumberText:null}
                                color='primary'  
                                rows={1}
                                variant='outlined' 
                                fullWidth = {true}
                                onChange ={onPhoneNumberChange}                        
                            />
                        </Grid>  
                    </Box>  
                    <Box ml={38} mt={-6}> 
                        <Fab size='small' color='secondary' disabled={!SendServiceData.canSendServicesText} onClick={sendServiceToYouth}>
                            <Icon>send</Icon> 
                        </Fab>
                    </Box>                                    
                </Grid>
            </Box>
        </div>
    )
}