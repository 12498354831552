const SendServiceData = {}
SendServiceData.canSendServicesText = false
SendServiceData.personallNoteText = ''
SendServiceData.generatedText = ''
SendServiceData.phoneNumberText = ''
//SendServiceData.emailAddress = ''  // TBA?
SendServiceData.updateServiceScreen = {}
SendServiceData.servicesManifest    = {}  // raw services information object in a form that can generate an email or a text
SendServiceData.URL = ''


SendServiceData.clearAll = () => {
    SendServiceData.canSendServicesText = false
    SendServiceData.personallNoteText = ''
    SendServiceData.generatedText = ''
    SendServiceData.phoneNumberText = ''
    //SendServiceData.emailAddress = ''
    SendServiceData.updateServiceScreen = {}
    SendServiceData.servicesManifest    = {}
}

export default SendServiceData