import Geolocate from "../Services/Geolocate";
//import SettingsData from "../components/SettingsData";
import DiversionData from "../components/DiversionData"
import Utils from "./Utils";

const Format = {}

// Format functions format YSCBO data for display in the YSL UI
Format.distance = (distance, remote) => {
    let preface = (Geolocate.haveLast?"":"*")
    let ret = ""
    if (remote) {
        ret = "(remote service)"
    }
    else if (5 < distance) {
        ret = preface + "(" + Math.round(distance) + " mi)"
    }
    else if (1 < distance) {
        let halves = Math.round (distance * 2)
        Utils.trace (Utils.debugLocation,"D: "+distance+", H: "+halves)
        let extra = false
        if (1 === (halves%2))
        {
            extra = true
            halves -= 1
        }
        ret = preface +"(" + (halves/2) + (extra?".5":"") + " mi)"
    }
    else if (1 > distance) {
        let quarters = Math.round (distance * 4)
        switch (quarters) {
            case 4:
                ret = preface +"(1 mi)"
                break
            case 3:
                ret = preface +"(3/4 mi)"
                break
            case 2:
                ret = preface +"(1/2 mi)"
                break
            case 1:
                ret = preface +"(1/4 mi)"
                break
            case 0:
                ret = preface +"(close)"
                break
            default:
                ret = preface +""
                break
        }
    }
    Utils.trace (Utils.debugLocation,"distance: " + distance + ", formated: " + ret)
    return ret
}

Format.extractNumberString = (str) => {
    let ret = ''
    let digits = '0123456789'

    str += ';'
    for (let c of str) {
        let isDigit = -1!== digits.indexOf(c)
        if (isDigit) {
            ret += c
        }
    }
    return ret
}

Format.validName = (nameText) => {
    if (2 > nameText.length) {
        return false
    }
    return true
}

Format.validDoB = (dobText) => {
    if (6 > dobText.length) {
        return false
    }

    let numbers = Format.extractNumberString(dobText)
    //console.log("numbers " + JSON.stringify(numbers))
    if(4 > numbers.length) {
        return false
    }
    
    // strip out number groups
    let numberGroups = []
    let nextGroup = ""
    let digits = '0123456789'
    let testString = dobText + ";"
    for (let c of testString) {
        if (-1 !== digits.indexOf(c)) {
            nextGroup += c
        }
        else if (0 < nextGroup.length) {
            numberGroups.push(nextGroup)
            nextGroup = ""
        }
    }
    let dmy = Format.validDate(numberGroups)
    if (null===dmy) {
        return false
    }
    //console.log("dmy "+ JSON.stringify(dmy))

    // verify if in the "youth" range
    let inYouthRange = Format.isYouthsDoB(dmy[0],dmy[1],dmy[2])
    //console.log("inYouthRange: "+inYouthRange)
    
    // use undefined to mean the birthday is valid, but it does not qualify as a youth
    let ret = (inYouthRange?true:undefined) 
    //console.log("validDoB ret: "+ret)
    return ret
}
Format.setYouthContactMap = () => {
    // locate the youth's first and last name in the contact's full name
    DiversionData.YouthIsContact = []
    if (Format.isYouthThisContact(DiversionData.Contact1Name)) {
        DiversionData.YouthIsContact.push(1)
    }
    if (Format.isYouthThisContact(DiversionData.Contact2Name)) {
        DiversionData.YouthIsContact.push(2)
    }
    if (Format.isYouthThisContact(DiversionData.Contact3Name)) {
            DiversionData.YouthIsContact.push(3)
    }
    //console.log("sYCM()--"+JSON.stringify(DiversionData.YouthIsContact))
}

Format.stringCopy = (str) => {
    let ret = ""
    for (let c of str) {
        ret += c
    }
    return ret
}

Format.isYouthThisContact = (contact) => {
    if (0===contact.length) {
        return false
    }
    var cc = Format.stringCopy(contact).toLowerCase() 
    cc = cc.replaceAll(' ','')
    let fn = DiversionData.DivertedFirstName.toLowerCase()
    let ln = DiversionData.DivertedLastName.toLowerCase()
    //console.log("iYTC() contact initial: " + cc + ", fn: " +fn+ ", ln: " +ln)
    let index1 = cc.indexOf(fn)
    let index2 = cc.indexOf(ln)
    if ((-1===index1)|| (-1===index2)){
        //console.log("iYTC() missing youth name"+index1+","+index2)
        return false
    }
    else if (index1 > index2) {
        return false
    }
    //console.log("iYTC() contact before: |" + cc + "|")
    cc = cc.replace(fn,'')
    //console.log("iYTC() contact between: |" + cc + "|")
    cc = cc.replace(ln,'')
    //console.log("iYTC() contact after: |" + cc + "|")

    if (0 < cc.length) {
        //console.log("iYTC() after removng spaces and fn and ln, full name has more: "+c)
        return false
    }
    return true
}

Format.isYouthsDoB = (day,month,year) => {
    //console.log("min: "+DiversionData.YouthMinAge+", max: "+DiversionData.YouthMaxAge)
    let minYouthDate = new Date((year + DiversionData.YouthMinAge),(month-1),day)
    let maxYouthDate = new Date((year + DiversionData.YouthMaxAge),(month-1),day)
    let now = new Date(Date.now())
    //console.log("min: "+minYouthDate+", now: "+now+", max: "+maxYouthDate)
    //console.log("min: "+minYouthDate.toString()+", now: "+now.toString()+", max: "+maxYouthDate.toString())
    //console.log(">=min: " + (now >= minYouthDate) + ", <max" + (now < maxYouthDate))
    let isYouth = ((now >= minYouthDate) && (now < maxYouthDate))
    return isYouth
}

Format.calculateDobPlaceholder = () => {
    let now = new Date (Date.now())
    let day = now.getUTCDate()
    let month = now.getMonth() + 1
    let year = now.getFullYear() 
    //console.log("NOW: " + month + "/" +day+ "/" + year)
    let minDate = month + "/" + day + "/" + (year - DiversionData.YouthMinAge)
    //console.log("minDate: " + minDate)
    let maxDate = Format.dayAfter(day,month,year-DiversionData.YouthMaxAge)
    //console.log("maxDate: " + maxDate)
    return (DiversionData.DemoMode)?"DOB":"DOB from " +maxDate+ " to " +minDate+ "."
}

Format.daysInMonth = (month,isLeapYear) => {
    let daysInMonth = 31
    switch (month) {
        case 2:
            daysInMonth = (isLeapYear?29:28)
            break
        case 4:
        case 6: 
        case 9: 
        case 11:
            daysInMonth = 30
            break 
        default: 
            console.log("unexpected month: " + month)
            break
    }
    return daysInMonth
}

Format.dayBefore = (day,month,year) => {
    if (1===day) {
        if (1===month) { 
            // from January 1 to December 31 in the prior year
            day = 31
            month = 11
            year -= 1
        }
        else {
            //keeps the same year
            month -= 1
            day = Format.daysInMonth(month,Format.isLeapYear(year))
        }
    }
    else {
        // keeps same month and year
        day -= 1
    }
    return month+ "/" + day + "/" + year
}

Format.dayAfter = (day,month,year) => {
    let daysInMonth = Format.daysInMonth(month,Format.isLeapYear(year))
    //console.log("dIM "+ daysInMonth +" | "+ month + " "+ day +" " + year)

    if ((12===month) && (day===31)){
            // from december 31st to january 1st the next year
            day = 1
            month = 1
            year += 1
    }
    else {
        // on the last day of a month other than december
        if (day===daysInMonth) {
            //the year stays the same
            day = 1
            month += 1
        }
        else { // a day that is not the last in the month
            // month any year are the same
            day += 1
        }
    }
    return month+ "/" + day + "/" + year
}


Format.validDate = (numberGroups) => {
    //console.log("numberGroups: " + numberGroups)
    if (3 !== numberGroups.length) {
        return null
    }

    let month = numberGroups[0]
    if ((0===month.length)||(2<month.length))  {
        return null
    } 
    else {
        month = Number(month)
    }
    if (Number.isNaN(month)) {
        return null
    }

    let day = numberGroups[1]
    if ((0===day.length)||(2<day.length)) {
        return null
    } 
    else {
        day = Number(day)
    }
    if (Number.isNaN(month)) {
        return null
    }

    let year = numberGroups[2]
    if (4!==year.length) {
        return null
    } 
    else {
        year = Number(year)
    }
    if (Number.isNaN(year)) {
        return null
    }
    //console.log("d/m/y: "+day+"|"+month+"|"+year)
    let isLeapYear = Format.isLeapYear(year)

    if (!Format.isDayValidForMonth(day, month, isLeapYear)) {
        DiversionData.NonExistentDate = ""+month+"/"+day+"/"+year
        return null
    }
    return [day, month,year]
}

Format.isDayValidForMonth = (day, month, isLeapYear) => {
    if (1 > day) {
        return false
    }
    let daysInMonth =Format.daysInMonth(month,isLeapYear)
/*
    let daysInMonth = 31
    switch (month) {
        case 2:
            {
                daysInMonth = (isLeapYear?29:28)
            }
            break
        case 4:
        case 6: 
        case 9: 
        case 11:
            daysInMonth = 30
            break   
    }

*/
    //console.log("day/daysInMonth:  " + day + "<=" + daysInMonth)
    return (day <= daysInMonth)
}
   
Format.isLeapYear = (year) => {
// Check to see if it a leap year
    // Leap Years are any year that can be exactly divided by 4 (such as 2020, 2024, 2028, etc)
 	// except if it can be exactly divided by 100, then it isn't (such as 2100, 2200, etc)
 	// except if it can be exactly divided by 400, then it is (such as 2000, 2400)
     let isLeapYear = false
     if (0 === (year % 4)) {
         isLeapYear = true
         if (0 === (year % 100)) {
             isLeapYear = false
             if (0 === (year % 400)) {
                 isLeapYear = true
             }
         }
     }
     //console.log("isLeapYear: "+ year + ", " + isLeapYear)
     return isLeapYear
}

Format.validPhoneNumber = (phoneNumberText) => {
    if (undefined===phoneNumberText)
        return false
    if (''===phoneNumberText)
        return false
    // should be 10 digits once stripped of non digits. 
    let number = Format.extractNumberString(phoneNumberText) 
    // console.log("text: "+phoneNumberText+", count: "+number.length)
    return (10===number.length)
}

Format.validEmailAddress = (emailAddress) => {
    if (undefined===emailAddress)
        return false
    var atPos = emailAddress.indexOf('@')
    if (atPos<=0) 
        return false
    var emailDomain = emailAddress.substring(atPos+1, emailAddress.length)
    var domainDotPos = emailDomain.indexOf('.')
    //console.log ("emailDomain " + emailDomain)
    if (domainDotPos<=0) 
        return false
    return true
}

/*
Format.serving = (service) => {
    // TODO should this limit to certain attribute tags:
    // children teens all ages and those requested
    let attributeTags = service["attribute_tags"]
    let str = Utils.convertTagArrayToString(attributeTags)
    return str.charAt(0).toUpperCase() + str.slice(1)
}

Format.mainServices = (service) => {
    // TODO  should this limit to the service tags that
    // were requested
    let serviceTags = service["service_tags"]
     let str = Utils.convertTagArrayToString(serviceTags)
     return str.charAt(0).toUpperCase() + str.slice(1) + "."
}

Format.languages = (serviceLanguages, closestOffice) => {
    let officeLanguages = closestOffice["supported_languages"]
    let count = officeLanguages.length
    for (let index=0;index<count;++index) {
        let language = officeLanguages[index]
        switch (language) {
            case 'english':
            case 'en':
            case 'eng':
                language = "English"
                break;
            case 'hrv':
                language = "Croatian"
                break;    
            default:
                console.log("Unhandled language abbreviation: "+ language)
                break;
        }
        if (!serviceLanguages.includes(language)) {
            serviceLanguages.push(language)
        }
    }
    return Utils.convertTagArrayToString(serviceLanguages)
}

Format.address = (closestOffice) => {
    // format address string. e.g "2236 East 10th St, Indianapolis, IN 46201"
    let extra = closestOffice["address2"]
    if ((undefined === extra) || ("" === extra) || (null === extra)) {
        extra = ""
    }
    else {
        extra = ", " + extra
    }
    let ret = 
        closestOffice["address1"] + extra + ", " +
        closestOffice["city"] + ", " +
        closestOffice["state"] + " " +
        closestOffice["postal"] 
    return ret
}
 
Format.openTimes = (closestOffice)  => {
    let hours = closestOffice["hours"]
    let open = {}
    let count = Utils.weeksDays.length
    for(let index=0;index<count;++index) {
        let day = Utils.weeksDays[index]
        if (hours[day]) {
            let allDay = day +"_all_day"
            let openTime = day +"_start"
            let closeTime = day +"_finish"
            let midnight = "12:00 AM"
            let openAllDay = hours[allDay] || ((hours[openTime]===midnight) && (hours[closeTime]===midnight))
            if (openAllDay) {
                open[day] = "24"
            }
            else {
                open[day] = Utils.cleanTimeString(hours[openTime]) + "-" + Utils.cleanTimeString(hours[closeTime])
            }
        }
    }
    let openToday = false
    let openTomorrow = false
    let todayIndex = (new Date()).getDay()
    if (Object.keys(open).includes(Utils.weeksDays[todayIndex])) {
        openToday = true
    }
    if (Object.keys(open).includes(Utils.weeksDays[(todayIndex + 1)%7])) {
        openTomorrow = true
    }

    Utils.trace(traceFormat,("open: " + JSON.stringify(open,null,3)))
    let sortedHours = {}
    Object.keys(open).forEach(day => {
        let span = open[day]
        if (undefined === sortedHours[span]) {
            sortedHours[span] = [day]
        }
        else {
            (sortedHours[span]).push(day)
        }
    })
    Utils.trace(traceFormat,"sortedHours: " + JSON.stringify(sortedHours,null,3))
    let openString = ""
    let daysOff = []
    Object.keys(open).forEach(day => {
        if (!hours[day]) {
            daysOff.push(day)
        }
    })
    if ((0===daysOff.length) &&  (undefined !== sortedHours['24'])) {
        openString = "24/7"
    }
    else {
        let keys = Object.keys(sortedHours)
        let sortedHourCount= keys.length
        let index = -1
        keys.forEach(span => {
            index +=1
            let lastKey = (index === (sortedHourCount-1))
            let [first, last] = Utils.daysInSequence(sortedHours[span])
            let hoursOpen = ('24'===span)?"24 hrs":span
            if (first!==last) {
                let daySpan = (('sunday'===first)&&('saturday'===last))?'Every day': (Utils.abbreviateDay(first) + "-" + Utils.abbreviateDay(last))
                openString = daySpan + "(" + hoursOpen + ")" + (lastKey?"":" ")
            }
            else {
                let dayString = '';
                (sortedHours[span]).forEach(day => {
                    dayString += Utils.abbreviateDay(day)
                })
                openString += dayString + "(" + hoursOpen + ")" + (lastKey?"":" ")
            }
        })
    }
    let rareOpen = (3 > (Object.keys(open)).length)
    return [openString, rareOpen, openToday, openTomorrow]
}

Format.nextSteps = (nextStepsArray) => {
    let ret = ""
    if (undefined!==nextStepsArray){
        nextStepsArray.forEach(step => {
            let action  = step["action"]
            let channel = step["channel"] 
            let contact = step["contact"]
            let use = false
            let stepString = action + " via " + channel + " at " + contact + ". "
            Utils.trace(traceFormat,"NS:"+stepString)
            if (('location' === channel) && ('_' === contact)) {
                Utils.trace(traceFormat,"next confusing: " + stepString)
            }
            else if ('email' === channel) {
                stepString = action + " by emailing " + contact + ". "
                use = true
            }
            else if (('external_online' === channel) || ('website' === channel))
            {
                stepString = action + " via this website:  " + contact + ". "
                use = true
            }
            else {
                use = true
            }

            if (use) {
                ret += stepString.charAt(0).toUpperCase() + stepString.slice(1)
            }
        })
    }
    if (0===ret.length) {
        ret = "Contact the provider for eligibility requirements. "
    }
    Utils.trace(traceFormat, "formatNextSteps: "+ ret)
    return ret 
}

Format.eligibility = (serviceDetails,serviceData) => {
    let rulesObject = serviceDetails["rules"] 
    let ruleAttributes = serviceDetails["rule_attributes"]
    Utils.trace(traceFormat,"rulesObject "+ JSON.stringify(rulesObject,null,3))
    let ret = ""
    if (undefined!==rulesObject) {
        rulesObject.forEach(rule => {
            rule = rule.charAt(0).toUpperCase() + rule.slice(1)
            ret += (rule + " ")
        })
    }
    if (undefined!==ruleAttributes) {
        Utils.trace(traceFormat, "***** HAS "+ruleAttributes.length+" RULE ATTRIBUTES *****" + JSON.stringify(ruleAttributes,null,3))
    }

    if (0 === ret.length) {
        ret =  serviceData["nextSteps"]
    }
    
    Utils.trace(traceFormat,"formatEligibility: "+ ret)
    return ret
}

Format.coverage = (serviceDetails) => {
    let coverageDescription = serviceDetails["coverage_description"]
    let state = SettingsData.policeDepartment["state abbreviation"]
    let listsStates = coverageDescription.includes("states:")
    let hasState = coverageDescription.includes(state)
    if (listsStates && hasState) {
        coverageDescription = "This service has statewide coverage."
    }
    return coverageDescription
}

Format.genderSpecific = (service) => {
    let at = service["attribute_tags"]
    let male = at.includes("male")
    let female = at.includes("female")
    if (female && !male) {
        return "Female only. "
    }
    else if (male && !female) {
        return "Male only. "
    }
    return ""
}

const remoteServices = [
    "virtual support",
    //"support network", 
    "help hotlines", 
]

Format.provideRemoteServices = (serviceDetails) => {
    let hRST = hasRemoteServiceTags(serviceDetails)
    let pRS  = providesServiceRemotely(serviceDetails["next_steps"])
    return hRST ||  pRS
}

const hasRemoteServiceTags = (service) => {
    let serviceTags = service["service_tags"]
    let count = serviceTags.length
    for (let index =0; index<count; ++index) {
        let tag = serviceTags[index]
        let hasRemoteTag = remoteServices.includes(tag)
        if (hasRemoteTag) {
            Utils.trace(traceFormat,"TAG: "+tag)
            return true
        }
    }
    return false
}

const providesServiceRemotely = (nextStepsArray) => {
    if (undefined!==nextStepsArray){
        nextStepsArray.forEach(step => {
            let action  = step["action"]
            let channel = step["channel"] 
            // if the service is remote
            if (('get services' === action) && 
                (   ('phone'=== channel) || 
                    ('website'=== channel) || 
                    ('email'=== channel)
                )) {
                return true
            }
        })
    }
    return false
}
*/
export default Format