import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, TextField , Button, Box, Paper, } from '@material-ui/core';
import SfyLogo from './images/SFY_logo.png'
//import AuntBerthaLogo from './images/AuntBerthaLogo.png'

// global data
import SettingsData from "./SettingsData";
import RegistrationData from "./RegistrationData";

function handleVerification(setScreen)
{
    //console.log("VS.handleVerification()")
    setScreen('verification-error-screen')
}

function isCodeVerifiable(verificationCode) {
    if (verificationCode ==='')
        return false
    if (9!==verificationCode.length)
        return false
    // make the correctly sized verificationCode availible for comparison
    RegistrationData.verificationCodeFromClient = verificationCode.toUpperCase() 
    return true
}

export default function VerificationScreen(props) {
    const [canVerify, setVerifiable] = React.useState(false);
    const onCodeChange = event => {
        let code = event.target.value
        setVerifiable (isCodeVerifiable(code))
    };
    //console.log('Show Verification Screen')
    let verificationPlaceholder = 'We sent a code to '+SettingsData.getActualEmailAddress()
    let testingString = SettingsData.getTestingString()

    return ( 
        <Grid container direction='column' spacing={3}>
            <Grid>
                <SfyHeader screenId={'verification-screen'}  lastScreenId={props.lastScreenId} setScreen={props.setScreen}  Settings={false} />
            </Grid>
            <Paper style={{maxHeight: '100vh', overflow: 'auto'}}>
                <Box ml={3} mt= {2} mr={3}>
                    <img src={SfyLogo} alt='SFY Logo' />
                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                POLICE DEPARTMENT
                            </Grid>
                        </Box>

                        <Grid item>
                            <Button fullWidth size='large' color='primary' variant="outlined">{SettingsData.policeDepartment['agency']}</Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                EMAIL ADDRESS
                            </Grid>
                        </Box>
                        <Grid item>
                            <Button fullWidth size='large' color='primary' variant="outlined">{SettingsData.getActualEmailAddress() + testingString}</Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                VERIFICATION CODE
                            </Grid>
                        </Box>
                        <Grid item>
                            <TextField 
                                variant='outlined' 
                                fullWidth color='primary' 
                                placeholder={verificationPlaceholder}
                                onChange ={onCodeChange}
                                />
                        </Grid>
                    </Grid>

                    <Box mt={3}>
                        <Grid item xs={12} sm={6}>
                            <Button 
                                onClick={() => {handleVerification(props.setScreen);}}
                                variant="contained" 
                                color="primary" 
                                disabled={!canVerify}
                            >
                                Verify Registration
                            </Button>
                        </Grid> 
                    </Box>
        
                    {/*
                    <Box mt={3}>
                        <Grid container direction='row' >
                            Powered by:
                            <Box mt={-1}>
                                <img src={AuntBerthaLogo} alt='Aunt Bertha Logo' mx='auto' />
                            </Box>
                        </Grid>
                    </Box>
                    */}
                    
                </Box>
            </Paper>
        </Grid >
    );
}