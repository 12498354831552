const DiversionData = {}

// DIVERSION SETUP--PERSISTS FOR LOCALE
DiversionData.diversionTitles             = []  // the ordered array of diversion titles 
DiversionData.YouthMinAge                 = 0
DiversionData.YouthMaxAge                 = 21
DiversionData.DemoMode                    = false
DiversionData.ClearDiversion              = true

////////////////////////////////////////////////////
// DIVERSION DATA
// any change here needs to be made in 
// DiversionData.ClearDiversionData() as well
DiversionData.selectedDiversions          = []  // titles of selected diversions from diversionTitles
DiversionData.DivertedFirstName           = ""  //
DiversionData.DivertedLastName            = ""  //
DiversionData.YouthIsContact              = []
DiversionData.YouthName                   = ""  //
DiversionData.HaveYouthName               = false
DiversionData.DivertedDoB                 = ""  //
DiversionData.DiversionTime               = "<date/time to be added>"
DiversionData.LatAtDiversionChoice        = ""
DiversionData.LongAtDiversionChoice       = ""
DiversionData.GeoCoordinates              = "<lattitude and longitude to be added>"
DiversionData.Contact1Name                = "" 
DiversionData.Contact1Phone               = "" 
DiversionData.HaveValid1Phone             = false
DiversionData.HaveValidDoB                = false
DiversionData.NonExistentDate             = ""
DiversionData.Contact1Email               = "" 
DiversionData.HaveValid1Email             = true
DiversionData.Contact1Note                = "" 
DiversionData.Contact2Name                = "" 
DiversionData.Contact2Phone               = "" 
DiversionData.HaveValid2Phone             = false 
DiversionData.Contact2Email               = "" 
DiversionData.HaveValid2Email             = true
DiversionData.Contact2Note                = "" 
DiversionData.Contact3Name                = "" 
DiversionData.Contact3Phone               = "" 
DiversionData.HaveValid3Phone             = false
DiversionData.Contact3Email               = "" 
DiversionData.HaveValid3Email             = true
DiversionData.Contact3Note                = "" 
DiversionData.CanContact                  = false 
DiversionData.DiversionNote               = ""
DiversionData.IgnoreContact2              = false
DiversionData.IgnoreContact3              = false
// DIVERSION DATA
////////////////////////////////////////////////////

DiversionData.ClearDiversionData = () => {    
    // SYNC WITH DIVERSION DATA ABOVE
    DiversionData.selectedDiversions          = []  // titles of selected diversions from diversionTitles
    DiversionData.DivertedFirstName           = ""  //
    DiversionData.DivertedLastName            = ""  //
    DiversionData.YouthIsContact              = []
    DiversionData.YouthName                   = ""  //
    DiversionData.HaveYouthName               = false
    DiversionData.DivertedDoB                 = ""  //
    DiversionData.DiversionTime               = "<date/time to be added>"
    DiversionData.GeoCoordinates              = "<lattitude and longitude to be added>"
    DiversionData.Contact1Name                = "" 
    DiversionData.Contact1Phone               = "" 
    DiversionData.HaveValid1Phone             = false
    DiversionData.HaveValidDoB                = false
    DiversionData.NonExistentDate             = ""
    DiversionData.Contact1Email               = "" 
    DiversionData.HaveValid1Email             = true
    DiversionData.Contact1Note                = "" 
    DiversionData.Contact2Name                = "" 
    DiversionData.Contact2Phone               = "" 
    DiversionData.HaveValid2Phone             = false 
    DiversionData.Contact2Email               = "" 
    DiversionData.HaveValid2Email             = true
    DiversionData.Contact2Note                = "" 
    DiversionData.Contact3Name                = "" 
    DiversionData.Contact3Phone               = "" 
    DiversionData.HaveValid3Phone             = false
    DiversionData.Contact3Email               = "" 
    DiversionData.HaveValid3Email             = true
    DiversionData.Contact3Note                = "" 
    DiversionData.CanContact                  = false 
    DiversionData.DiversionNote               = ""
    //DiversionData.IgnoreContact2              = false
    //DiversionData.IgnoreContact3              = false
}

export default DiversionData