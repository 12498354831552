import React from 'react';
import { Grid, TextField , Select, Button, FormControl, MenuItem, Box, Paper, } from '@material-ui/core';
import SfyHeader from "./SfyHeader"
import SfyLogo from './images/SFY_logo.png'
//import AuntBerthaLogo from './images/AuntBerthaLogo.png'

// global data
import PoliceDepartmentData from "./PoliceDepartmentData";
import RegistrationData from "./RegistrationData";
import SettingsData from "./SettingsData";

function validRegistrationEmailAddress(emailAddress) {
    emailAddress = emailAddress.toLowerCase()
    var atPos = emailAddress.indexOf('@')
    var emailDomain = (atPos>0) ? emailAddress.substring(atPos+1, emailAddress.length) : ''
    
    // has no @ 
    if (atPos<=0) {
        return false
    }
    //console.log('PD |'+ SettingsData.policeDepartment)
    
    // has @ 
    let expectedEmailDomain = SettingsData.policeDepartment['email domain']
    //console.log('expected |'+expectedEmailDomain+'| , actual |'+emailDomain)

    // no agency selected
    if (undefined===expectedEmailDomain) {
        return false
    }

    // has @ and the domain matches the departments domain
    let eq1 = (expectedEmailDomain.toLowerCase() === emailDomain.toLowerCase())
    //console.log('expected |'+expectedEmailDomain+'| , actual |'+emailDomain+'| okay: ' + eq1)
    if (eq1) {
        return true
    }
    let testerStr = '.tester'
    let testerPos =  emailDomain.indexOf(testerStr)
    let trimmedEmailDomain = emailDomain
    let testerLength = testerStr.length
    let testerEnd = testerPos + testerLength
    //console.log("email: " + emailDomain + " [" +testerPos+","+testerEnd+ "]")
    let isTester = (-1 !== testerPos)

    if (isTester){
        trimmedEmailDomain = emailDomain.substring(0,testerPos)  + emailDomain.substring(testerEnd)
        //console.log("trim tester: " + trimmedEmailDomain)
    }
    let newCatsStr = '.update'
    let newCatsPos =  trimmedEmailDomain.indexOf(newCatsStr)
    let newCatsLength = newCatsStr.length
    let newCatsEnd = newCatsPos + newCatsLength
    //console.log("trimmedEmailDomain: " + trimmedEmailDomain + " [" +newCatsPos+","+newCatsEnd+ "]")

    let isCatTester = (-1 !== newCatsPos)
    if (isCatTester) {
        trimmedEmailDomain = trimmedEmailDomain.substring(0,newCatsPos) + trimmedEmailDomain.substring(newCatsEnd)
        //console.log("trim newcats: " + trimmedEmailDomain)
    }

    let eq2 = (expectedEmailDomain.toLowerCase() === trimmedEmailDomain.toLowerCase())
    //console.log('expected |'+expectedEmailDomain+'| , actual |'+trimmedEmailDomain+'| okay: ' + eq2)
    if (eq2) {
        //console.log("EQ2--test: " +isTester+ ", catTest: " + isCatTester)
        //SettingsData.CacheLocally('globalTester',false)
        window.localStorage.setItem('globalTester', "false")
        if (isCatTester) {
            SettingsData.categoryTester = true
            //SettingsData.CacheLocally('categoryTester',true)
            window.localStorage.setItem('categoryTester', "true")
        }
        if (isTester) {
            //console.log("Unexpectedly has disallowed .tester annotation for a department email.")
            return false
        }
        return true
    }
    else {
        //console.log("NOT EQ2--test: " +isTester+ ", catTest: " + isCatTester)
        if (isTester) {
            SettingsData.globalTester = true
            //SettingsData.CacheLocally('globalTester',true)
            window.localStorage.setItem('globalTester', "true")
            if (isCatTester) {
                SettingsData.categoryTester = true
                //SettingsData.CacheLocally('categoryTester',true)
                window.localStorage.setItem('categoryTester', "true")
            }
            else {
                SettingsData.categoryTester = false
                //SettingsData.CacheLocally('categoryTester',false)
                window.localStorage.setItem('categoryTester', "false")
            }
            let untrimmedDomain = emailDomain.substring(0,testerPos)
            //console.log("ED: " + emailDomain + ", UD: " + untrimmedDomain + "["+0+","+testerPos+"]" )
            let domainLength = emailDomain.length
            let trimmedLength = untrimmedDomain.length
            let tLength = trimmedLength + testerLength
            let tcLength = trimmedLength + testerLength + newCatsLength
            //console.log("DL: " +domainLength+ ", TL: "+tLength+", TCL: "+tcLength) 
            let good = ((domainLength === tLength) ||
                        (domainLength === tcLength))
            return good
        }
        else {
            //console.log("categoryTester Z")
            SettingsData.categoryTester = false
            //SettingsData.CacheLocally('categoryTester',false)
            window.localStorage.setItem('categoryTester', "false")
            return false
        }
    }
}

export default function RegisterScreen(props) {
    //const classes = useStyles();
    var setScreen = props.setScreen
    RegistrationData.userAndDepartmentAccepted = false

    const [policeDepartment, setDepartment] = React.useState(SettingsData.policeDepartment);
    const [emailAddress, setEmail] = React.useState(SettingsData.rawEmailAddress);
    const [canRegister, setRegisterable] = React.useState(RegistrationData.canRegister);
    //if (canRegister)
    //    alert( "policeDepartment: "+policeDepartment+" emailAddress: "+ emailAddress + " canRegister:" + canRegister) 

    const handleDepartment = event => {
        let departmentDomain = event.target.value
        //console.log("handleDepartment "+ departmentDomain)
        if (departmentDomain in PoliceDepartmentData.registeredDepartments) {
            let department = PoliceDepartmentData.registeredDepartments[departmentDomain]
            //console.log("handleDepartment "+ JSON.stringify(department,null,3))
            setDepartment(department);
            SettingsData.policeDepartment = department
            //SettingsData.CacheLocally('policeDepartment',department)
            window.localStorage.setItem('policeDepartment', JSON.stringify(department))
            
            let vpd = PoliceDepartmentData.validPoliceDepartment(department)
            let vea = validRegistrationEmailAddress(emailAddress)
            //console.log("VPD"+vpd+ ", VEA" + vea)
            let register = (vpd && vea)
            setRegisterable(register)
            RegistrationData.canRegister = register
        }
        else {
            RegistrationData.canRegister = false
        }
     };

    const handleEmail = event => {
        var address = event.target.value
        setEmail (address);        
        SettingsData.rawEmailAddress = address 
        window.localStorage.setItem('emailAddress', address)
        let vpd = PoliceDepartmentData.validPoliceDepartment(policeDepartment)
        let vea = validRegistrationEmailAddress(address)
        let register = (vpd && vea)
        setRegisterable(register)
        RegistrationData.canRegister = register
    };

    const  handleRegister = () => {
        setScreen('register-error-screen')
    } 

    let departments = PoliceDepartmentData.registeredDepartments 
    //console.log("departments: " + JSON.stringify(departments,null,3))
    let departmentKeys = Object.keys(PoliceDepartmentData.registeredDepartments)
    //console.log("departmentKeys: " + departmentKeys)
    let domain = policeDepartment['email domain']
    let selectionValue = ("{}" === JSON.stringify(policeDepartment))?"empty": domain
    let emailPlaceholder = "Enter Email Address " + ((undefined !== domain)?("@"+domain):"")
    return ( 
        <Grid container direction='column' spacing={3}>
            <Grid>
                <SfyHeader screenId={'register-screen'}  setScreen={props.setScreen} Settings={false} />
            </Grid >
            <Paper style={{maxHeight: '100vh', overflow: 'auto'}}>
                <Box ml={3} mt= {3} mr={3}>
                    <img src={SfyLogo} alt='SFY Logo' />
                    <Grid item xs={12} sm={6}>
                        <Box mt= {3} >
                            <Grid item>
                                POLICE DEPARTMENT
                            </Grid>
                        </Box>
                        
                        <Grid item>
                            <FormControl variant="outlined" hiddenLabel fullWidth color='primary'>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectionValue}
                                    displayEmpty
                                    onChange={handleDepartment}
                                    label="Police Department"
                                >
                                    <MenuItem value="empty"><i>Select Police Department</i></MenuItem>
                                    {[...Array(departmentKeys.length).keys()].map((index) => {
                                        let emailDomain = departmentKeys[index]
                                        let menuText = departments[emailDomain]['agency'] + " ("+ departments[emailDomain]['zip code'] + ")"
                                        return (
                                            <MenuItem key={emailDomain} value={emailDomain}>
                                                {menuText}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>      
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                EMAIL ADDRESS
                            </Grid>
                        </Box>
                            <Grid item>
                                <TextField 
                                    variant='outlined' 
                                    fullWidth color='primary' 
                                    value={emailAddress}
                                    placeholder={emailPlaceholder} 
                                    onChange ={handleEmail}
                                    />
                            </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Button 
                                onClick={handleRegister}
                                variant="contained" 
                                color="primary" 
                                disabled={!canRegister}
                            >
                                Create Account
                            </Button>
                        </Box>
                    </Grid> 

                    {/*
                    <Box mt={3}>
                        <Grid container direction='row' >
                            Powered by:
                            <Box mt={-1}>
                                <img src={AuntBerthaLogo} alt='Aunt Bertha Logo' mx='auto' />
                            </Box>
                        </Grid>
                    </Box>
                    */}
                    
                </Box>
            </Paper>
        </Grid>    
    );
}