import React from 'react';
import AllServiceTypesList from "./AllServiceTypesList"
import SfyHeader from "./SfyHeader"

import { Grid, Fab, Icon, Box } from '@material-ui/core';

// global data
import SendServiceData from "./SendServiceData"
import ServerData from './ServerData';
import DiversionData from './DiversionData';
import SessionData from './SessionData';
import SettingsData from "./SettingsData"
import TransmitOnline from "./TransmitOnline"

const generateSendServicesText = () => {
  //https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/Advanced_text_formatting

  let servicesText = ''
  var sevicesArray = []
  for (let serviceNumericId of SessionData.selectedServices)
  {
    let serviceData   = SettingsData.allServices[serviceNumericId] 
    let service       = serviceData.service
    let organization  = serviceData.organization
    let address       = serviceData.address
    let googleMapUrl = ""
    if ((undefined!==address) && (0<address.length)) {
      googleMapUrl = 'https://www.google.com/maps/search/?api=1&query=' + (organization + ' '  + address).replace(/ /g,'+') 
    }
    let about         = serviceData.about
    let nextSteps     = serviceData.nextSteps
    let website       = serviceData.website
    let phone         = serviceData.phone

    // store each sevice in an object in sevicesArray
    var serviceObject = {}
    serviceObject.service = service
    serviceObject.organization = organization
    serviceObject.address = address
    serviceObject.googleMapUrl = googleMapUrl
    serviceObject.about = about
    serviceObject.nextSteps = nextSteps
    serviceObject.website =  website
    serviceObject.phone =  phone
    sevicesArray.push(serviceObject)

    servicesText += 'SERVICE: "' + service + '" provided by ' + organization + 
                  ( ((undefined!==address) && (0<address.length))?(' at '
                   + address  + '\n' ):'\n') + 
                  'ABOUT: ' + about + '\n' +
                  'NEXT STEPS: ' + nextSteps + 
                  (((undefined!==phone) && (0<phone.length))?('\nPHONE: ' + phone ):'') + 
                  (((undefined!==website) && (0<website.length))?('\nWEBSITE: ' + website ):'') + 
                  (((undefined!==googleMapUrl) && (0<googleMapUrl.length))?('\nGOOGLE MAP: ' + googleMapUrl ):'') + 
                  '\n- - - - - - - - - - - - - - -\n\n' 
  }

  let serviceCount = SessionData.selectedServices.length
  let howToText =  ((1===serviceCount)? 
                    'The following service text describes a recommended service. ':
                    'Each of following '+  serviceCount + ' texts describes one recommended service.') +
                    ' Read the "NEXT STEPS" section on how to connect with the service.' +
                    ' Most services have links for address, website, phone, and Google maps.' +
                    ' Tap on any link to explore that information. '
  howToText += SettingsData.localExtraTextHeaderInfo
  SendServiceData.servicesManifest.services = sevicesArray
  SendServiceData.servicesManifest.howTo    = howToText
  //console.log("howToText: " + howToText)
  SendServiceData.generatedText = howToText + '\n\n' + servicesText
  //console.log("generatedText: " + SendServiceData.generatedText)
}

export default class FindServices extends React.Component {
  state = {
    ignore: false,
    haveDiversionTitles: false,
  }

  constructor(props){
    super()
    this.updateSelections = this.updateSelections.bind(this)
    this.showSendServicesScreen  = this.showSendServicesScreen.bind(this)
    SessionData.updateFindServicesPage = this.updateSelections
  }

  updateSelections(){
    //console.log("FindServies.updateSelections()")
    this.setState({ignore: !this.state.ignore })
  }

  showSendServicesScreen(){
    generateSendServicesText()
    if (typeof(SendServiceData.updateServiceScreen) === "function") 
      SendServiceData.updateServiceScreen()
    this.props.setScreen('send-services')
  }


  
  async componentDidMount() { 
    // console.log("SDS.componentDidMount(): "  + ((SessionData.sendingNote)?" ":" not") + " sending " + SendServiceData.URL)
    if (SessionData.loadServicesFailed) {
      console.log ("FS.cDM SD.loadServicesFailed")
      if (null!==this.props.setScreen) {
        SessionData.loadServicesFailed = false
        console.log ("FS.cDM calling this.props.setScreen('reset-app')")
        alert("An error occurred loading services data. Automatically reloading.")
        this.props.setScreen('reset-app')
      }
    }
    else if (SessionData.sendingNote) {
      try {
        // create an event for texting
        var services = SendServiceData.servicesManifest.services
        var servicesString = ""
        services.forEach(serviceObject => {
          servicesString += serviceObject.service + ", "
        })
        TransmitOnline.reportEvent ("textingService",servicesString)
        //console.log(">>>>> FS.componentDidMount--servicesString: " + servicesString)
        var bodyText = JSON.stringify(SendServiceData.servicesManifest)
        //console.log(">>>>> FS.componentDidMount: " + SendServiceData.URL)
        //console.log(">>>>> FS.componentDidMount body: " + bodyText)

        const requestOptions = {
            method: 'POST',
            // When I am including "mode: 'no-cors'," Apparently "Content-Type" devolves 
            // to text and body does not come through to the server handler
            // I am not sure why I don't get CORS errors though
            // mode: 'no-cors', 
            headers: { 
              "Accept":"application/json", 
              "Content-Type":"application/json"
            },
            body: bodyText
        };
        const rawResponse = await fetch(SendServiceData.URL, requestOptions)
        console.log("The text request response status is: " + rawResponse.status)
        if (rawResponse.status !== 200) {
          alert("The text request unexpectedly returned status #", rawResponse.status)
        }
      }
      catch (error) {
        alert("The internet is not currently accessible. Retry your last attempt when you are back online.")
        console.log("The text request unexpectedly threw an error: ", typeof(error))
      }
      SessionData.sendingNote = false
    }

    // cache the diversion options from the server
    if (0===DiversionData.diversionTitles.length) {
      DiversionData.diversionTitles = []
        var url = (ServerData.getServerRootUrl() + 
            "listOffenseData?" +
            "agency=" + SettingsData.policeDepartment['email domain'] + 
            "&appUser=" + SettingsData.getActualEmailAddress() +
            "&sessionKey=" + SettingsData.sessionKey)
        url = url.replace(/ /g,'%20')
        //console.log("get diversion options: " + url)           
        const response = await fetch(url)
        const data = await response.text()
        //console.log("data: " + data)
        let keys = Object.keys(JSON.parse(data))
        if (0<keys.length) {
          DiversionData.diversionTitles = keys
          this.setState({haveDiversionTitles: true})
        }
        //console.log("get diversion options--status " + response.status + ", " + JSON.stringify(DiversionData.diversionTitles,null,3) + " **** " + DiversionData.diversionTitles.length)
    }
  }

  render () {
    //console.log("FindServices.render()")
    var serviceCount = SessionData.selectedServices.length
    var sendServicesTitle = (0===serviceCount)?"SEND SERVICES": ("SEND " +serviceCount+ " SERVICE" + ((1<serviceCount)?"S":""))
    var contextData = "SFY"
    var priorScreenId = ""
    if (1 < DiversionData.diversionTitles.length) {
      priorScreenId = "diversion-options-screen"
    }
    return ( 
      <div>
        <Grid container direction='column' alignItems='stretch' spacing={0}>
          <SfyHeader  screenId={'find-services'} context={contextData} lastScreenId={priorScreenId} setScreen={this.props.setScreen}  Settings={true}/>
          <Box mt={3} ml= {2}>
            <Fab color='secondary' variant="extended" disabled={(0===serviceCount)} onClick={this.showSendServicesScreen}>
              {sendServicesTitle}
              <Icon>send</Icon> 
            </Fab>
          </Box>
          <Grid container direction='column' alignItems='stretch' justifyContent='space-between'  >
            <AllServiceTypesList servicesData={SettingsData.allServices} diversionTitles={DiversionData.diversionTitles} setScreen={this.props.setScreen} /> 
          </Grid>
        </Grid>
      </div>
    );
  }
}



